import React from 'react';
import { Button } from '@/components/ui/shadcn/button';
import { Heart } from 'lucide-react';
import { cn } from "@/lib/utils";
import SupportDialog from '@/components/ui/controls/SupportDialog';

interface SupportButtonProps {
  className?: string;
  variant?: 'button' | 'link';
}

const SupportButton = ({ className, variant = 'button' }: SupportButtonProps) => {
  const [showSupportDialog, setShowSupportDialog] = React.useState(false);

  return (
    <>
      {variant === 'button' ? (
        <Button
          variant="default"
          size="sm"
          onClick={() => setShowSupportDialog(true)}
          className={cn(
            "inline-flex items-center gap-2 rounded-lg px-2 sm:px-3 py-1.5",
            "bg-[#FFDD00] hover:bg-[#FFDD00]/90",
            "text-black font-semibold text-xs sm:text-sm",
            "transition-colors duration-200",
            "shadow-sm hover:shadow",
            "whitespace-nowrap",
            className
          )}
        >
          <Heart className="h-4 w-4" />
          Show Support
        </Button>
      ) : (
        <Button
          variant="default"
          className={cn(
            "w-full flex items-center justify-center gap-2 px-4 py-2 rounded-lg",
            "bg-[#FFDD00] hover:bg-[#FFDD00]/90",
            "text-black font-medium",
            className
          )}
          onClick={() => setShowSupportDialog(true)}
        >
          <Heart className="h-5 w-5" />
          Show Support
        </Button>
      )}
      
      <SupportDialog
        isOpen={showSupportDialog}
        onClose={() => setShowSupportDialog(false)}
      />
    </>
  );
};

export default SupportButton;

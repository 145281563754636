import newRecruitLogo from '@/assets/images/newrecruit_logo.webp';
import bcpLogo from '@/assets/images/bcp_logo.png';
import statsAndLaddersLogo from '@/assets/images/SL_LogoLive.png';
import excelLogo from '@/assets/images/excel_logo.png';

export const PLATFORM_CONFIGS = {
  'tp-new-recruit': {
    logo: newRecruitLogo,
    logoClass: 'h-6 w-6 rounded-full object-cover bg-white',
    displayName: 'New Recruit',
    idPattern: /^[a-z0-9]+$/i,
    exampleId: '66d240a94db39a05bbd25ee2',
    exampleUrl: 'https://www.newrecruit.eu/app/tournydetails/rankings?id=66d240a94db39a05bbd25ee2',
    domain: 'newrecruit.eu',
    supportsBatchImport: false,
    isFileUpload: false
  },
  'tp-bcp': {
    logo: bcpLogo,
    logoClass: 'h-6 w-6 rounded-full object-cover bg-white',
    displayName: 'BCP',
    idPattern: /^[A-Za-z0-9]{10,16}$/,
    exampleId: 'GP2ixHjdk4ZQ',
    exampleUrl: 'https://www.bestcoastpairings.com/event/MA8BDED4FT',
    domain: 'bestcoastpairings.com',
    supportsBatchImport: true,
    isFileUpload: false
  },
  'tp-stats-and-ladders': {
    logo: statsAndLaddersLogo,
    logoClass: 'h-6 w-6 rounded-full object-cover bg-white',
    displayName: 'Stats & Ladders',
    idPattern: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i,
    exampleId: 'a4c894b8-d284-45ca-bdbb-3f3c927f24b6',
    exampleUrl: 'https://my.statsandladders.com/tournament/show/a4c894b8-d284-45ca-bdbb-3f3c927f24b6',
    domain: 'statsandladders.com',
    supportsBatchImport: false,
    isFileUpload: false
  },
  'tp-excel': {
    logo: excelLogo,
    logoClass: 'h-6 w-6 rounded-full object-cover bg-white',
    displayName: 'Excel Import',
    supportsBatchImport: false,
    isFileUpload: true
  }
} as const;

export type PlatformIdentifier = keyof typeof PLATFORM_CONFIGS;

import {Head, usePage} from '@inertiajs/react';
import { StaticPage } from "@/components/layouts/StaticPageLayout.tsx";
import {ShowSupportContents} from "@/components/ui/support/ShowSupportContents.tsx";

const PricingPage = () => {
  return (
    <>
      <Head title="Pricing" />
      <StaticPage title="Pricing">
        <ShowSupportContents />
      </StaticPage>
    </>
  );
};

export default PricingPage;

import React from 'react';
import { Link } from '@inertiajs/react';
import { SidebarMenu, SidebarMenuItem, SidebarMenuButton, useSidebar } from "@/components/ui/shadcn/sidebar";
import { cn } from "@/lib/utils";
import { useNavigation } from '../NavigationContext';

interface AppSidebarItemProps {
  icon: React.ElementType;
  label: string;
  href: string;
  disabled?: boolean;
  external?: boolean;
  onClick?: () => void;
  active?: boolean;
}

export function AppSidebarItem({
                                 icon: Icon,
                                 label,
                                 href,
                                 disabled = false,
                                 external = false,
                                 onClick,
                                 active = false,
                               }: AppSidebarItemProps) {
  const { isMobile, setOpenMobile } = useSidebar();
  const { activeItem } = useNavigation();
  
  const isActive = active || activeItem === href;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick();
    }
    if (isMobile) {
      setOpenMobile(false);
    }
  };

  const buttonContent = (
    <>
      <Icon className="h-7 w-7 shrink-0" />
      <span className="text-base">{label}</span>
    </>
  );

  const buttonClassName = cn(
    "w-full justify-start",
    "min-h-[48px] md:min-h-[40px]", // Larger touch target on mobile
    "flex items-center gap-2",
    "px-4 py-3 md:py-2", // More padding on mobile
    "text-base md:text-sm", // Larger text on mobile
    isActive
      ? "!bg-warhammer-red-dark/30 !text-white hover:bg-warhammer-red-dark/40"
      : "text-warhammer-gold hover:text-white hover:bg-warhammer-red-dark/30",
    disabled && "opacity-50 cursor-not-allowed"
  );

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        {external ? (
          <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SidebarMenuButton
              className={buttonClassName}
              onClick={handleClick}
              disabled={disabled}
              tooltip={label}
            >
              {buttonContent}
            </SidebarMenuButton>
          </a>
        ) : (
          <Link href={href} preserveScroll={false} preserveState={false}>
            <SidebarMenuButton
              className={buttonClassName}
              onClick={handleClick}
              disabled={disabled}
              tooltip={label}
            >
              {buttonContent}
            </SidebarMenuButton>
          </Link>
        )}
      </SidebarMenuItem>
    </SidebarMenu>
  );
}

import * as React from "react"
import {
  BarChart,
  Calendar, CalendarArrowUp, CalendarCheck, CalendarDays,
  Cpu,
  Globe, LayoutDashboard, ListTodo,
  Trophy, UserCog, Users,
} from "lucide-react"
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarRail, useSidebar,
} from "@/components/ui/shadcn/sidebar"
import { AppSidebarGroup } from "@/components/layouts/sidebar/AppSidebarGroup.tsx";
import { AppSidebarItem } from "@/components/layouts/sidebar/AppSidebarItem.tsx";
import Guard from "@/components/Guard.tsx";
import {Link, usePage} from "@inertiajs/react";
import DiscordButton from "@/components/ui/controls/DiscordButton.tsx";
import SupportButton from "@/components/ui/controls/SupportButton.tsx";
import wtowLogo from '@/assets/images/wtow_logo.png';
import {cn} from "@/lib/utils.ts";

interface NavItem {
  icon: React.ElementType;
  label: string;
  href: string;
  disabled?: boolean;
  external?: boolean;
  requiresRegionAdmin?: boolean;
  requiresSuperAdmin?: boolean;
}

interface NavGroup {
  label: string;
  requiresRegionAdmin?: boolean;
  requiresSuperAdmin?: boolean;
  items: NavItem[];
}

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const { current_user, current_region } = usePage().props;
  const { state: sidebarState, isMobile } = useSidebar();
  const isCollapsed = sidebarState === 'collapsed';

  const navGroups: NavGroup[] = [
    {
      label: "Regional",
      items: [
        {
          icon: LayoutDashboard,
          label: "Dashboard",
          href: `/${current_region.slug}/dashboard`
        },
        {
          icon: Trophy,
          label: "Masters Rankings",
          href: `/${current_region.slug}/rankings`,
          disabled: !current_region.scoring_enabled
        }
      ]
    },
    {
      label: "Tournaments",
      items: [
        {
          icon: CalendarDays,
          label: "Latest Results",
          href: `/${current_region.slug}/tournaments/latest`
        },
        {
          icon: CalendarArrowUp,
          label: "Upcoming",
          href: `/${current_region.slug}/tournaments/upcoming`
        },
        {
          icon: CalendarCheck,
          label: "Finished",
          href: `/${current_region.slug}/tournaments`
        }
      ]
    },
    {
      label: "Other Information",
      items: [
        {
          icon: Users,
          label: "Players",
          href: "/players"
        }
      ]
    },
    {
      label: "Region Admin",
      requiresRegionAdmin: true,
      items: [
        {
          icon: ListTodo,
          label: "Feedback",
          href: `/${current_region.slug}/feedback`,
          requiresRegionAdmin: true
        },
        {
          icon: Calendar,
          label: "Seasons",
          href: "/seasons",
          requiresRegionAdmin: true
        }
      ]
    },
    {
      label: "Super Admin",
      requiresSuperAdmin: true,
      items: [
        {
          icon: UserCog,
          label: "Users",
          href: "/users",
          requiresSuperAdmin: true
        },
        {
          icon: Globe,
          label: "Regions",
          href: "/regions",
          requiresSuperAdmin: true
        },
        {
          icon: BarChart,
          label: "Platform Stats",
          href: "/admin/stats",
          requiresSuperAdmin: true
        },
        {
          icon: Cpu,
          label: "Background Jobs",
          href: "/admin/jobs",
          requiresSuperAdmin: true,
          external: true
        }
      ]
    }
  ];

  const canSeeRegionAdminItems = current_user?.is_region_admin || current_user?.is_super_admin;
  const canSeeSuperAdminItems = current_user?.is_super_admin;

  const shouldShowGroup = (group: NavGroup): boolean => {
    if (group.requiresSuperAdmin && !canSeeSuperAdminItems) return false;
    if (group.requiresRegionAdmin && !canSeeRegionAdminItems) return false;
    return true;
  };

  return (
    <Sidebar collapsible="icon" {...props} className="top-14">
      <SidebarHeader>
        <div className={cn(
            "hidden lg:block mb-4",
            isCollapsed ? "lg:hidden" : "lg:w-full"
        )}>
          <Link href={`/${current_region.slug}`}>
            <img
                src={wtowLogo}
                alt="Warhammer: The Old World Logo"
                className="w-full h-auto"
            />
          </Link>
        </div>
      </SidebarHeader>

      <SidebarContent>
        {navGroups.map((group, index) => shouldShowGroup(group) && (
            <AppSidebarGroup key={`${group.label}-${index}`} label={group.label}>
              {group.items.map((item, itemIndex) => {
                const NavItem = (
                  <AppSidebarItem
                    key={`${item.label}-${itemIndex}`}
                    icon={item.icon}
                    label={item.label}
                    href={item.href}
                    disabled={item.disabled}
                    external={item.external}
                />
              );

              if (item.requiresSuperAdmin) {
                return <Guard key={`${item.label}-${itemIndex}`} requiresSuperAdmin>{NavItem}</Guard>;
              }

              if (item.requiresRegionAdmin) {
                return <Guard key={`${item.label}-${itemIndex}`} requiresRegionAdmin>{NavItem}</Guard>;
              }

              return NavItem;
            })}
          </AppSidebarGroup>
        ))}
      </SidebarContent>

      <SidebarFooter className="mt-auto border-t border-warhammer-gold/20">
        {!isCollapsed && (
          <div className="p-2 flex flex-col gap-2">
            {isMobile && <SupportButton variant="link" />}
            <DiscordButton />
          </div>
        )}
      </SidebarFooter>

      <SidebarRail />
    </Sidebar>
  )
}

import { Link, usePage } from '@inertiajs/react';
import { ExternalLink } from 'lucide-react';
import gommo from '@/assets/images/gommo.png';
import BuyMeCoffee from "@/components/ui/controls/BuyMeACoffee";
import { Button } from "@/components/ui/controls/Button";
import paypalLogo from '@/assets/images/paypal_logo.png';

// Import the cn function that merges Tailwind classes:
import { cn } from '@/lib/utils';
import DiscordButton from "@/components/ui/controls/DiscordButton.tsx";

type ShowSupportContentsProps = {
};

export function ShowSupportContents({}: ShowSupportContentsProps) {
  const { front_end_configs } = usePage().props;
  const { paypal_link } = front_end_configs;

  const isDark = true;

  return (
    <div
      className={cn(
        'space-y-6',
        // Conditionally switch text color
        isDark ? 'text-white' : 'text-black'
      )}
    >
      {/* Free Tournament Rankings Section */}
      <section className="mb-8">
        <h2
          className={cn(
            'text-2xl font-semibold mb-4',
            // Warhammer gold can remain the same, or be switched if you want a different color in light mode
            'text-warhammer-gold'
          )}
        >
          Free Tournament Rankings
        </h2>
        <p
          className={cn(
            // Example of a conditional “secondary” text color
            isDark ? 'text-white/90' : 'text-black/80'
          )}
        >
          Old World Rankings is completely free for tournament organizers and players.
          We believe in supporting the Warhammer: The Old World community by providing
          accessible tournament management and ranking services.
        </p>
      </section>

      {/* Supporting Development Section */}
      <section className="mb-8">
        <h2
          className={cn(
            'text-2xl font-semibold mb-4',
            'text-warhammer-gold'
          )}
        >
          Supporting Development
        </h2>
        <p
          className={cn(
            'mb-6',
            isDark ? 'text-white/90' : 'text-black/80'
          )}
        >
          While our platform is free to use, we welcome support from the community
          to help cover our hosting costs and fund future development. You can support us through:
        </p>

        {/* Support Buttons */}
        <div className="flex flex-col sm:flex-row gap-4 my-6">
          <BuyMeCoffee className="w-full sm:w-auto justify-center" />

          <Button
            onClick={() => window.open(paypal_link, '_blank')}
            className={cn(
              'w-full sm:w-auto bg-warhammer-gold text-warhammer-black hover:bg-yellow-400',
              'border-2 border-yellow-600 hover:border-yellow-500 transition-colors duration-200',
              'font-bold rounded-md flex items-center justify-center gap-2'
            )}
          >
            <img src={paypalLogo} alt="PayPal Logo" className="h-4 w-auto" />
            <span>Support via PayPal</span>
          </Button>

          <DiscordButton className="w-full sm:w-auto justify-center"/>
        </div>
      </section>

      {/* Future Vision Section */}
      <section className="mb-12">
        <h2
          className={cn(
            'text-2xl font-semibold mb-4',
            'text-warhammer-gold'
          )}
        >
          Our Vision
        </h2>
        <p
          className={cn(
            'mb-6',
            isDark ? 'text-white/90' : 'text-black/80'
          )}
        >
          We're building Old World Rankings to be more than just a tournament tracker. Our vision is to create
          the central hub for the competitive Warhammer: The Old World community.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-4">
            <h3
              className={cn(
                'text-xl font-semibold',
                'text-warhammer-gold'
              )}
            >
              Platform Features
            </h3>
            <ul
              className={cn(
                'list-disc pl-6 space-y-2',
                isDark ? 'text-white/90' : 'text-black/80'
              )}
            >
              <li>Comprehensive meta statistics and analysis</li>
              <li>Global ranking system for competitive play</li>
              <li>Tools to help professionalize the competitive scene</li>
              <li>Tournament hosting and management</li>
              <li>Hobby profiles to showcase your armies and achievements</li>
              <li>Social features to connect the community</li>
            </ul>
          </div>

          <div className="space-y-4">
            <h3
              className={cn(
                'text-xl font-semibold',
                'text-warhammer-gold'
              )}
            >
              Community Goals
            </h3>
            <ul
              className={cn(
                'list-disc pl-6 space-y-2',
                isDark ? 'text-white/90' : 'text-black/80'
              )}
            >
              <li>Foster a welcoming competitive environment</li>
              <li>Support tournament organizers and players</li>
              <li>Provide insights into the evolving meta</li>
              <li>Create a platform for sharing hobby progress</li>
              <li>Build connections between players globally</li>
            </ul>
          </div>
        </div>
      </section>

      {/* About Me Section */}
      <section className="mb-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-start">
          <div className="space-y-4">
            <h2
              className={cn(
                'text-2xl font-semibold mb-4',
                'text-warhammer-gold'
              )}
            >
              About Me
            </h2>
            <p
              className={cn(
                isDark ? 'text-white/90' : 'text-black/80'
              )}
            >
              I'm the host of{' '}
              <Link
                href='https://oldworldfanatics.com'
                className="underline"
              >
                Old World Fanatics
              </Link>
              , a podcast dedicated to competitive Warhammer: The Old World.
              Through the podcast and this platform, I'm working to build and support our growing community.
            </p>

            <div className="space-y-4 mt-6">
              <a
                href="https://oldworldfanatics.com"
                target="_blank"
                rel="noopener noreferrer"
                className={cn(
                  'flex items-center gap-2',
                  'text-warhammer-gold',
                  // Here you could conditionally change hover color, e.g.
                  // isDark ? 'hover:text-warhammer-gold-dark' : 'hover:text-warhammer-gold-light'
                  'hover:text-warhammer-gold-dark transition-colors'
                )}
              >
                <ExternalLink className="h-5 w-5" />
                <span>oldworldfanatics.com</span>
              </a>

              <a
                href="https://www.youtube.com/@oldworldfanatics"
                target="_blank"
                rel="noopener noreferrer"
                className={cn(
                  'flex items-center gap-2',
                  'text-warhammer-gold',
                  'hover:text-warhammer-gold-dark transition-colors'
                )}
              >
                <ExternalLink className="h-5 w-5" />
                <span>YouTube Channel</span>
              </a>

              <a
                href="https://www.patreon.com/oldworldfanatics"
                target="_blank"
                rel="noopener noreferrer"
                className={cn(
                  'flex items-center gap-2',
                  'text-warhammer-gold',
                  'hover:text-warhammer-gold-dark transition-colors'
                )}
              >
                <ExternalLink className="h-5 w-5" />
                <span>Join our Patreon</span>
              </a>
            </div>
          </div>

          <div
            className={cn(
              'rounded-lg overflow-hidden',
              // Example background color difference
              isDark ? 'bg-black/30' : 'bg-white/70'
            )}
          >
            <div className="w-full h-[200px] bg-gray-800 flex items-center justify-center text-gray-600">
              <img
                src={gommo}
                alt="Gommo"
                className="h-full w-full object-cover"
              />
            </div>
            <div className="mt-8">
              <span
                className={cn(
                  'font-handwriting text-4xl',
                  'text-warhammer-gold'
                )}
              >
                gommo
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

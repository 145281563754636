import { useState } from 'react';
import { Link } from '@inertiajs/react';
import {ListPlus, NotepadText, ScrollText} from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/shadcn/dialog";
import { Button } from "@/components/ui/shadcn/button";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/shadcn/tooltip";
import { usePage } from '@inertiajs/react';
import { TournamentResult } from "@/components/ui/tournaments/common/TournamentPodiumInfo.tsx";
import { Tournament } from "@/types/Tournament.types.ts";
import {FeedbackDialog} from "@/components/ui/feedback/FeedbackDialog.tsx";
import {ArmyListContent} from "@/components/ui/feedback/ArmyListContent.tsx";

interface ArmyListButtonProps {
  tournament: Tournament;
  result: TournamentResult;
}

export default function ArmyListButton({ tournament, result }: ArmyListButtonProps) {
  const { current_user } = usePage().props;
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const hasList = result.has_list;

  const ButtonContent = () => (
    <button
      className={`
        w-8 h-8 flex items-center justify-center
        border border-border/50 rounded transition-colors
        ${hasList ? 'bg-primary text-primary-foreground hover:bg-primary/90' : 'bg-warhammer-gold text-warhammer-black hover:bg-warhammer-gold-dark'}
      `}
    >
      {hasList ? (
        <ScrollText className="h-5 w-5" />
      ) : (
        <ListPlus className="h-5 w-5" />
      )}
    </button>
  );

  // If user is not logged in and trying to view a list
  if (!current_user && hasList) {
    return (
      <>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div onClick={() => setShowLoginPrompt(true)}>
                <ButtonContent />
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <p>View army list</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>

        <Dialog open={showLoginPrompt} onOpenChange={setShowLoginPrompt}>
          <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle>Army Lists are for Members Only</DialogTitle>
            </DialogHeader>
            <div className="pt-6">
              <p className="text-sm text-muted-foreground mb-6">
                Login or create an account to view tournament army lists and track your own tournament results.
              </p>
              <div className="flex justify-end space-x-4">
                <Button
                  className="bg-warhammer-gold text-warhammer-black hover:bg-warhammer-gold-dark"
                  onClick={() => window.location.href = `${tournament.friendly_url}?list=${result.id}`}>
                  Sign in / Sign up
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  }

  if (!hasList) {
    return (
      <>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div onClick={() => setShowFeedback(true)}>
                <ButtonContent />
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <p>Submit army list</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>

        <FeedbackDialog
          isOpen={showFeedback}
          onClose={() => setShowFeedback(false)}
          title={`Submit Army List for ${result.player.full_name}`}
          parent={{
            type: "TournamentResult",
            id: result.id
          }}
          subject={{
            type: "ArmyList"
          }}
        >
          <ArmyListContent />
        </FeedbackDialog>
      </>
    );
  }

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Link
            href={`${tournament.friendly_url}?list=${result.id}`}
            preserveState
            preserveScroll
          >
            <ButtonContent />
          </Link>
        </TooltipTrigger>
        <TooltipContent>
          <p>View army list</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

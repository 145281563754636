import {usePage} from '@inertiajs/react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/shadcn/card";
import UpcomingTournamentsCard from "@/components/ui/dashboard/UpcomingTournamentsCard.tsx";
import PageHeader from "@/components/layouts/PageHeader.tsx";
import FactionLeadersTable from "@/components/ui/dashboard/FactionLeadersTable.tsx";
import FactionAppearanceChart from "@/components/ui/dashboard/FactionAppearanceChart.tsx";
import FactionWinRateChart from "@/components/ui/dashboard/FactionWinRateChart.tsx";
import {useLinkPlayerPrompt} from "@/hooks/useLinkPlayerPrompt.ts";
import LinkPlayerPromptDialog from "@/components/ui/profile/LinkPlayerPromptDialog.tsx";
import {Tournament} from "@/types/Tournament.types.ts";
import ScoringDisabledDialog from "@/components/ui/dashboard/ScoringDisabledDialog.tsx";
import TopPlayersCard from "@/components/ui/dashboard/TopPlayersCard.tsx";
import LatestWinningLists from "@/components/ui/dashboard/LatestWinningLists.tsx";

interface PageProps {
  rankings: RankingData[];
  totalTournaments: number;
  totalPlayers: number;
  factionStats: { name: string; count: number; winRate: number }[];
  upcomingTournaments: Tournament[];

}

const Index = () => {
  const {
    rankings,
    total_tournaments,
    total_players,
    faction_stats,
    upcoming_tournaments,
    latest_winning_lists,
    current_region,
    current_season,
    factions
  } = usePage<PageProps>().props;

  const { overall_rankings, faction_rankings } = rankings;
  const { showPrompt, closePrompt } = useLinkPlayerPrompt();
  const topFiveRankings = overall_rankings.slice(0, 5);

  return (
    <div className="p-4 md:p-6 space-y-6">
      <PageHeader
        title={`${current_season.name} - ${current_region.name} Region`}
        showSeasonSelector={true}
      />

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <Card>
          <CardHeader>
            <CardTitle className="text-xl font-semibold flex items-center">
              <span className="text-2xl mr-2">🏟️</span> Total Tournaments
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-3xl font-bold">{total_tournaments}</p>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle className="text-xl font-semibold flex items-center">
              <span className="text-2xl mr-2">🤺</span> Regional Players
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-3xl font-bold">{total_players}</p>
          </CardContent>
        </Card>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <LatestWinningLists results={latest_winning_lists}/>
        <UpcomingTournamentsCard tournaments={upcoming_tournaments}/>
      </div>

      <TopPlayersCard rankings={topFiveRankings}/>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <FactionAppearanceChart factionStats={faction_stats} factions={factions}/>
        <FactionWinRateChart factionStats={faction_stats} factions={factions}/>
      </div>

      <div className="col-span-full">
        <FactionLeadersTable factionRankings={faction_rankings} factions={factions}/>
      </div>

      {!current_region.scoring_enabled && <ScoringDisabledDialog/>}
      <LinkPlayerPromptDialog isOpen={showPrompt} onClose={closePrompt}/>
    </div>
  );
};

export default Index;

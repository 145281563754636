import React, {useState} from 'react';
import { DataTable } from "@/components/ui/controls/tables/DataTable";
import { router } from '@inertiajs/react';
import {
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getFilteredRowModel,
  ColumnFiltersState,
  SortingState
} from '@tanstack/react-table';
import { createColumns, type FeedbackItem } from "./FeedbackTableColumns";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/shadcn/select";
import FeedbackReviewDialog from "@/components/ui/feedback/FeedbackReviewDialog";
import {Feedback} from "@/components/ui/feedback/Types.ts";
import ReviewStatusBadge from "@/components/ui/feedback/ReviewStatusBadge.tsx";
import {useSearchParams} from "@/hooks/useSearchParams.ts";

interface FeedbackDataTableProps {
  data: FeedbackItem[];
  onView: (feedback: FeedbackItem) => void;
}

export default function FeedbackDataTable({ data, onView }: FeedbackDataTableProps) {
  const [selectedFeedback, setSelectedFeedback] = useState<Feedback | null>(null);

  const [sorting, setSorting] = React.useState<SortingState>([
    { id: 'date', desc: true }
  ]);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentStatus = searchParams.get('status') || 'open';

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  
  React.useEffect(() => {
    if (currentStatus !== 'all') {
      setColumnFilters([{ id: 'status', value: currentStatus }]);
    } else {
      setColumnFilters([]);
    }
  }, [currentStatus]);
  
  const columns = createColumns((feedback) => {
    setSelectedFeedback(feedback);
  });

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    state: {
      sorting,
      columnFilters,
    },
    meta: {
      onView
    }
  });

  const handleStatusFilter = (value: string) => {
    router.get(window.location.pathname, 
      { status: value },
      { 
        preserveState: true, 
        preserveScroll: true,
        only: ['feedback']
      }
    );
  };

  return (
    <div className="space-y-4 pt-6">
      <div className="flex items-center gap-4">
        <span>Filter by:</span>
        <Select
          value={currentStatus}
          onValueChange={handleStatusFilter}
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Filter by status" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Status</SelectItem>
            <SelectItem value="open"><ReviewStatusBadge status="open" /></SelectItem>
            <SelectItem value="approved"><ReviewStatusBadge status="approved" /></SelectItem>
            <SelectItem value="rejected"><ReviewStatusBadge status="rejected" /></SelectItem>
            <SelectItem value="archived"><ReviewStatusBadge status="archived" /></SelectItem>
          </SelectContent>
        </Select>
      </div>

      <DataTable
        columns={columns}
        table={table}
      />

      {selectedFeedback && (
        <FeedbackReviewDialog
          isOpen={!!selectedFeedback}
          onClose={() => setSelectedFeedback(null)}
          feedback={selectedFeedback}
        />
      )}
    </div>
  );
}

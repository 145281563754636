import { useState } from 'react'
import { usePage } from '@inertiajs/react'
import { ChevronsUpDown } from "lucide-react"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/shadcn/dropdown-menu"
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@/components/ui/shadcn/sidebar"
import { cn } from "@/lib/utils"

interface Region {
  code: string
  name: string
  country_flag: string
  slug: string
  coming_soon: boolean
}

interface PageProps {
  current_region: Region
  regions: Region[]
}

interface RegionSwitcherProps {
  variant?: 'default' | 'compact';
}

export function RegionSwitcher({ variant = 'default' }: RegionSwitcherProps) {
  const { current_user, current_region, regions } = usePage<PageProps>().props
  const { isMobile } = useSidebar()
  const [isOpen, setIsOpen] = useState(false)

  const getRedirectPath = (newRegionSlug: string) => {
    const currentPath = window.location.pathname;
    // Preserve existing search params
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('region', newRegionSlug);

    // Global routes that should be preserved as-is
    const globalRoutes = ['/players', '/profile', '/pricing', '/terms', '/privacy', '/admin/stats'];
    if (globalRoutes.some(route => currentPath.startsWith(route))) {
      return `${currentPath}?${searchParams.toString()}`;
    }

    // Player profile routes should be preserved
    if (currentPath.startsWith('/@')) {
      return `${currentPath}?${searchParams.toString()}`;
    }

    // Region-scoped routes that should be preserved with new region
    const currentPathWithoutRegion = currentPath.replace(`/${current_region.slug}`, '');
    const regionScopedRoutes = [
      '/rankings',
      '/tournaments',
      '/tournaments/upcoming',
      '/tournaments/latest',
      '/feedback'
    ];

    if (regionScopedRoutes.some(route => currentPathWithoutRegion === route)) {
      // Preserve all existing query parameters except region
      const currentParams = new URLSearchParams(window.location.search);
      currentParams.delete('region');
      const queryString = currentParams.toString();
      return `/${newRegionSlug}${currentPathWithoutRegion}${queryString ? `?${queryString}` : ''}`;
    }

    // Default to region root
    return `/${newRegionSlug}`;
  };

  const handleRegionChange = (regionSlug: string) => {
    if (regionSlug === 'global') {
      window.location.href = '/';
    } else {
      window.location.href = getRedirectPath(regionSlug);
    }
  };

  // Only show non-coming-soon regions unless user is super admin
  const availableRegions = current_user?.is_super_admin
    ? regions
    : regions.filter(region => !region.coming_soon);

  const sortedRegions = [...availableRegions].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size="lg"
              className="w-full !h-10 min-h-[40px] max-h-[40px] justify-between bg-white dark:bg-zinc-800/40 border-0 hover:!bg-white dark:hover:!bg-zinc-800/60"
            >
              <div className="flex items-center gap-2 min-w-0">
                <span className="text-2xl">{current_region.country_flag}</span>
                {variant === 'default' && (
                  <span className={cn(
                    "flex-1 truncate text-base text-foreground"
                  )}>
                    {current_region.name}
                  </span>
                )}
              </div>
              <ChevronsUpDown className="ml-auto h-4 w-4 shrink-0 text-foreground group-data-[collapsible=icon]:hidden" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-[--radix-dropdown-menu-trigger-width] min-w-[200px] rounded-lg bg-white dark:bg-zinc-800"
            align="start"
            side={isMobile ? "bottom" : "right"}
            sideOffset={4}
          >
            <DropdownMenuItem
              onClick={() => handleRegionChange('global')}
              className="gap-2 p-2 text-foreground cursor-pointer hover:bg-muted"
            >
              <span className="text-2xl">🌍</span>
              <span className="flex-1 truncate">Global Overview</span>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            {sortedRegions.map((region) => (
              <DropdownMenuItem
                key={region.code}
                onClick={() => handleRegionChange(region.slug)}
                className={cn(
                  "gap-2 p-2 text-foreground", // Added text-foreground
                  region.coming_soon && "opacity-50 cursor-not-allowed",
                  !region.coming_soon && "cursor-pointer hover:bg-muted" // Added hover state
                )}
                disabled={region.coming_soon}
              >
                <span className="text-2xl">{region.country_flag}</span>
                <span className="flex-1 truncate">{region.name}</span>
                {region.coming_soon && (
                  <span className="text-xs text-muted-foreground ml-auto">
                    Coming Soon
                  </span>
                )}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}

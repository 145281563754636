import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/shadcn/dialog";
import {ShowSupportContents} from "@/components/ui/support/ShowSupportContents.tsx";

interface SupportDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function SupportDialog({ isOpen, onClose }: SupportDialogProps) {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-[95vw] sm:max-w-7xl max-h-[90vh] bg-black/95 border-gray-800 overflow-y-scroll px-4 sm:px-6">
        <DialogHeader className="relative pr-6">
          <DialogTitle className="text-white text-xl sm:text-2xl">Support Development</DialogTitle>
          <button
            onClick={onClose}
            className="absolute right-0 top-0 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6 text-white">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
            <span className="sr-only">Close</span>
          </button>
        </DialogHeader>
        <ShowSupportContents />
      </DialogContent>
    </Dialog>
  );
}

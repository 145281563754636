import React, { useState, useEffect, useRef } from 'react';
import Header from "@/components/layouts/Header";
import {SidebarInset, SidebarProvider} from "@/components/ui/shadcn/sidebar.tsx";
import {AppSidebar} from "@/components/layouts/sidebar/AppSidebar.tsx";
import { NavigationProvider } from './NavigationContext';

interface LayoutProps {
  children: React.ReactNode;
}

const MainLayout = ({ children }: LayoutProps) => {
  return (
    <NavigationProvider>
      <SidebarProvider>
      <div className="relative flex min-h-svh flex-col bg-background text-foreground overflow-hidden">
        <Header/>
        <div
          className="relative flex w-full sm:w-screen overflow-y-scroll pt-14 transition-all duration-200 ease-linear"
          scroll-region="">
          <AppSidebar/>
          <div className="w-full max-w-full max-[600px]:max-w-[430px]">
            {children}
          </div>
        </div>
      </div>
      </SidebarProvider>
    </NavigationProvider>
  );
};

export default MainLayout;

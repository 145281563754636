import React from 'react';
import { Link, router, usePage } from '@inertiajs/react';
import { Settings, LogOut, User, InfoIcon } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/shadcn/dropdown-menu";
import Avatar from "@/components/ui/controls/Avatar";
import { Button } from "@/components/ui/shadcn/button";

export function ProfileDropdown() {
  const { current_user } = usePage().props;

  const handleLogout = () => {
    router.delete('/logout', {
      preserveState: false,
      preserveScroll: false,
    });
  };

  if (!current_user) {
    return (
      <Link href="/login">
        <Button
          variant="default"
          size="default"
          className="bg-warhammer-gold text-black hover:bg-warhammer-gold-dark transition-all duration-200 font-semibold border-2 border-warhammer-gold/20 shadow-[0_0_10px_rgba(255,215,0,0.2)]"
        >
          <span>Login</span>
        </Button>
      </Link>
    );
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          size="sm"
          className="relative h-10 w-10 rounded-full border-2 border-warhammer-gold p-0 overflow-hidden hover:shadow-[0_0_15px_rgba(255,215,0,0.3)] transition-all duration-200"
        >
          <Avatar
            name={current_user.first_name}
            email={current_user.email}
            size={40}
            round
          />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="w-64 bg-warhammer-black border border-warhammer-gold/30 shadow-[0_0_20px_rgba(255,215,0,0.1)]"
        align="end"
        forceMount
      >
        <DropdownMenuLabel className="border-b border-warhammer-gold/20 bg-black/40">
          <div className="flex flex-col space-y-1.5 p-2">
            <p className="text-base font-medium text-warhammer-gold">
              {current_user.first_name} {current_user.last_name}
            </p>
            <p className="text-sm text-warhammer-silver">
              {current_user.email}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuGroup>
          <Link href="/profile">
            <DropdownMenuItem className="py-3 cursor-pointer text-warhammer-silver hover:bg-warhammer-red-dark/30 hover:text-warhammer-gold focus:bg-warhammer-red-dark/30 focus:text-warhammer-gold">
              <Settings className="mr-2 h-5 w-5 text-warhammer-gold"/>
              <span className="text-base">Profile Settings</span>
            </DropdownMenuItem>
          </Link>
          <a
            href="https://headwayapp.co/oldworldrankings-com-updates"
            target="_blank"
            rel="noopener noreferrer"
          >
            <DropdownMenuItem className="py-3 cursor-pointer text-warhammer-silver hover:bg-warhammer-red-dark/30 hover:text-warhammer-gold focus:bg-warhammer-red-dark/30 focus:text-warhammer-gold">
              <InfoIcon className="mr-2 h-5 w-5 text-warhammer-gold"/>
              <span className="text-base">What's new on OWR?</span>
            </DropdownMenuItem>
          </a>
          <DropdownMenuItem
            onClick={handleLogout}
            className="py-3 cursor-pointer text-warhammer-silver hover:bg-warhammer-red-dark/30 hover:text-warhammer-gold focus:bg-warhammer-red-dark/30 focus:text-warhammer-gold"
          >
            <LogOut className="mr-2 h-5 w-5 text-warhammer-gold"/>
            <span className="text-base">Log out</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

import React from 'react';
import { FileSpreadsheet, Download, Info } from 'lucide-react';
import { Input } from "@/components/ui/shadcn/input";
import { Button } from "@/components/ui/controls/Button";
import { Alert, AlertDescription } from "@/components/ui/shadcn/alert";
import {usePage} from "@inertiajs/react";

interface ExcelImportFormProps {
  onFileChange: (file: File | null) => void;
  isImporting?: boolean;
}

const ExcelImportForm = ({ onFileChange, isImporting = false }: ExcelImportFormProps) => {
  const { current_region } = usePage().props;

  const downloadTemplate = () => {
    window.location.href = `/${current_region.slug}/tournaments/excel/template`;
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    onFileChange(selectedFile || null);
  };

  return (
    <div className="space-y-6">
      {/* Instructions */}
      <Alert className="bg-muted/50">
        <Info className="h-4 w-4 text-warhammer-gold" />
        <AlertDescription>
          <div className="space-y-2">
            <p className="text-sm font-medium text-foreground">How to import tournament results:</p>
            <ol className="text-sm space-y-1 list-decimal pl-4 text-muted-foreground">
              <li>Download our Excel template below</li>
              <li>Fill in your tournament details and results in the template</li>
              <li>Upload the completed file to import your tournament</li>
            </ol>
          </div>
        </AlertDescription>
      </Alert>

      {/* Template Download */}
      <Button
        variant="outline"
        className="flex items-center gap-2 w-auto bg-muted/50"
        onClick={downloadTemplate}
      >
        <Download className="h-4 w-4 text-muted-foreground" />
        <span>Download Template</span>
        <FileSpreadsheet className="h-4 w-4 text-green-600" />
      </Button>

      {/* File Upload Section */}
      <div className="relative">
        <Input
          type="file"
          accept=".xlsx,.xls"
          onChange={handleFileChange}
          className="cursor-pointer bg-muted/50"
          disabled={isImporting}
        />
      </div>
    </div>
  );
};

export default ExcelImportForm;

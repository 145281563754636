import React from 'react';
import { SidebarGroup, SidebarGroupLabel, SidebarGroupContent } from "@/components/ui/shadcn/sidebar";
import {cn} from "@/lib/utils.ts";

interface AppSidebarGroupProps {
  label: string;
  children: React.ReactNode;
  collapsible?: boolean;
}

export function AppSidebarGroup({label, children, collapsible = true}: AppSidebarGroupProps) {
  return (
    <SidebarGroup className={"px-2 py-0"}>
      <SidebarGroupLabel
        className={cn(
          "uppercase tracking-wider font-medium",
          "text-sm md:text-xs",
          "text-warhammer-gold/50",
          "px-4 pt-3 md:pt-2"
        )}
      >
        {label}
      </SidebarGroupLabel>
      <SidebarGroupContent>
        {children}
      </SidebarGroupContent>
    </SidebarGroup>
  );
}

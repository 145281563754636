import { useTheme } from '@/components/ThemeProvider';
import { Button } from '@/components/ui/shadcn/button';
import { Moon, Sun } from 'lucide-react';
import SupportButton from '@/components/ui/controls/SupportButton';
import owfLogoWhite from '@/assets/images/owr_logo_white.png';
import { Link, usePage, router } from '@inertiajs/react';
import { useNavigation } from './NavigationContext';
import { useEffect } from 'react';
import UniversalSearch from "@/components/ui/search/UniversalSearch";
import HeadwayWidget from "@/components/layouts/Headway";
import {SidebarTrigger, useSidebar} from "@/components/ui/shadcn/sidebar";
import { ProfileDropdown } from "./ProfileDropdown";
import { RegionSwitcher } from "./sidebar/RegionSwitcher";
import * as React from "react";

function Header() {
  const { theme, setTheme } = useTheme();
  const { state, isMobile } = useSidebar();
  const { setActiveItem } = useNavigation();
  const { url } = usePage();

  useEffect(() => {
    const path = url.replace(/^\/[a-z]{2}-[a-z]{2}/, '').split('?')[0];
    setActiveItem(path);
  }, [url, setActiveItem]);

  return (
    <header
      className="bg-warhammer-black fixed top-0 left-0 px-2 sm:px-4 py-2 z-10 flex justify-between items-center gap-2 w-full transition-[left,width] duration-200 ease-linear"
    >
      <div className="flex grow items-center gap-2">
        <SidebarTrigger className="mr-2 text-warhammer-gold hover:text-white shrink-0"/>
        <div className="relative md:w-56">
          <RegionSwitcher variant={ isMobile ? 'compact' : 'default'}/>
        </div>
        <div className="flex-1 mx-2 max-w-[200px] lg:max-w-[400px]">
          <UniversalSearch/>
        </div>
      </div>
      <div className="flex items-center gap-2 sm:gap-4 shrink-0">
        <div className="hidden lg:block">
          <SupportButton />
        </div>
        <div className="hidden sm:block">
          <HeadwayWidget />
        </div>
        <Button
          variant="ghost"
          size="icon"
          onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
          className="text-warhammer-gold hover:text-black"
        >
          {theme === 'dark' ? <Sun className="h-6 w-6" /> : <Moon className="h-6 w-6" />}
        </Button>
        <Link href={`/`} className="hidden sm:block">
          <img src={owfLogoWhite} alt="OWF Logo" className="h-8 w-8 mr-2" />
        </Link>
        <ProfileDropdown />
      </div>
    </header>
  );
}

export default Header;

import { useCallback } from 'react';

export function useSearchParams(): [URLSearchParams, (params: Record<string, string>) => void] {
  const searchParams = new URLSearchParams(window.location.search);

  const setSearchParams = useCallback((params: Record<string, string>) => {
    const newSearchParams = new URLSearchParams(window.location.search);
    
    Object.entries(params).forEach(([key, value]) => {
      if (value === null || value === undefined || value === '') {
        newSearchParams.delete(key);
      } else {
        newSearchParams.set(key, value);
      }
    });

    const newUrl = `${window.location.pathname}?${newSearchParams.toString()}`;
    window.history.pushState({}, '', newUrl);
    
    return newSearchParams;
  }, []);

  return [searchParams, setSearchParams];
}

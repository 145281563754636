import React, { useEffect } from 'react';
import { Head, router, usePage } from '@inertiajs/react';
import { useSearchParams } from '@/hooks/useSearchParams';
import FeedbackDataTable from "@/components/ui/feedback/FeedbackDataTable";
import PageHeader from "@/components/layouts/PageHeader";
import PageContainer from "@/components/layouts/PageContainer";
import { type FeedbackItem } from "@/components/ui/feedback/FeedbackTableColumns";

interface PageProps {
  feedback: FeedbackItem[];
}

export default function FeedbackAdmin() {
  const { feedback, current_region } = usePage<PageProps>().props;
  const [searchParams, setSearchParams] = useSearchParams();
  const currentStatus = searchParams.get('status') || 'open';



  const handleStatusChange = (id: number, status: string) => {
    router.post(`/${current_region.slug}/feedback/${id}/update_status`, {
      status
    }, {
      preserveState: true,
      preserveScroll: true
    });
  };

  return (
    <PageContainer
      header={
        <PageHeader
          title="Feedback Tasks"
          description="Manage and respond to feedback for your region"
        />
      }
    >
      <FeedbackDataTable
        data={feedback}
        onStatusChange={handleStatusChange}
      />
    </PageContainer>
  );
}
